import m, * as bacta from "bacta"

import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/components/drawer/drawer.js"

export type DrawerAttrs = {
	open?: boolean
	label?: string
	placement?: "top" | "end" | "bottom" | "start"
	contained?: boolean
	noHeader?: boolean
} & bacta.Attributes

export const Drawer: bacta.ClosureComponent<DrawerAttrs> = () => {
	return {
		view: ({ attrs, children }) => {
			return m("sl-drawer", attrs, children)
		},
	}
}
