import m, * as bacta from "bacta"
import { Input, InputAttrs } from "../sl/input"
import { AnyFunction } from "ramda"

type NumberAttrs = {
	type: "number"
	query: bacta.Store<number>
}

type TextAttrs = {
	type: "text"
	query: bacta.Store<string>
}

type DateAttrs = {
	type: "date"
	query: bacta.Store<Date | null>
}

type BaseAttrs = {
	sl?: Partial<InputAttrs>
	label?: string
	postop?: AnyFunction
}
export type MainAttrs = BaseAttrs & (NumberAttrs | TextAttrs | DateAttrs)

export const HarthInput: bacta.ClosureComponent<MainAttrs> = () => ({
	view: ({ attrs: { sl = {}, ...attrs }, children }) => {
		return m(
			Input,
			{
				type: attrs.type,
				["onsl-input"](e) {
					if (attrs.type === "date") {
						attrs.query.update(
							() => (e.target as HTMLInputElement)?.valueAsDate
						)
					} else if (attrs.type === "number") {
						attrs.query.update(
							() => (e.target as HTMLInputElement)?.valueAsNumber
						)
					} else {
						attrs.query.update(() => (e.target as HTMLInputElement)?.value)
					}

					if (attrs.postop) {
						attrs.postop()
					}
				},
				[attrs.type === "text"
					? "value"
					: attrs.type === "date"
					? "valueAsDate"
					: "valueAsNumber"]: attrs.query.get(),
				...sl,
			},
			children
		)
	},
})
