import m, * as bacta from "bacta"
import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/components/range/range.js"

export type RangeAttrs = {
	label?: string
	value?: number
	helpText: string
	max: number
	step: number
	"onsl-change": (event: InputEvent) => void
}
export const Range: bacta.ClosureComponent<RangeAttrs> = () => ({
	view: ({ attrs = {} }) => m("sl-range", attrs),
})
