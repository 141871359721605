/* eslint-disable no-mixed-spaces-and-tabs */
// todo-james paragraph isn't a regex, either rename module/function etc

/**
 * Formats a vlaue into money formats for human representation.
 *
 * e.g. prettyKey('500520') //=> $ 500,520.00
 */
// import { Store } from "jaforbes-s/store.js";
import m, { Store } from "bacta"

async function getBounds(store: Store<any>) {
	const [bounds] = await sql`
		select
			min(supplier_items_weight) as minweight
			,min(supplier_items_height) as minheight
			,min(supplier_items_wdith) as minwdith
			,min(supplier_items_length) as minlength
			,min(supplier_locations_items_cost) as mincost
			
			,max(supplier_items_weight) as maxweight
			,max(supplier_items_height) as maxheight
			,max(supplier_items_wdith) as maxwdith
			,max(supplier_items_length) as maxlength
			,max(supplier_locations_items_cost) as maxcost

		from app.supplier_locations_items
		left join app.supplier_items using(supplier_items_id)
	`

	store.prop("min_supplier_items_weight").update(() => bounds.minweight)
	store.prop("min_supplier_items_height").update(() => bounds.minheight)
	store.prop("min_supplier_items_wdith").update(() => bounds.minwdith)
	store.prop("min_supplier_items_length").update(() => bounds.minlength)
	store.prop("min_supplier_locations_items_cost").update(() => bounds.mincost)

	store.prop("max_supplier_items_weight").update(() => bounds.maxweight)
	store.prop("max_supplier_items_height").update(() => bounds.maxheight)
	store.prop("max_supplier_items_wdith").update(() => bounds.maxwdith)
	store.prop("max_supplier_items_length").update(() => bounds.maxlength)
	store.prop("max_supplier_locations_items_cost").update(() => bounds.maxcost)
}

async function setIndustryOptions(store: Store<any>) {
	const industries = await sql`
		select distinct 
			supplier_items_industry
		from app.supplier_items
	`

	store
		.prop("industryCategories")
		.update(() => industries.map((i: any) => i.supplier_items_industry))
}

async function searchCategories(store: Store<any>, s = "") {
	const search = s

	const supplier = store.prop("supplier_name").get()
	const name = store.prop("supplier_items_name").get()
	const description = store.prop("supplier_items_description").get()

	const minweight = store.prop("supplier_items_weight_min").get()
	const minheight = store.prop("supplier_items_height_min").get()
	const minwdith = store.prop("supplier_items_wdith_min").get()
	const minlength = store.prop("supplier_items_length_min").get()
	const mincost = store.prop("supplier_locations_items_cost_min").get()

	const maxweight = store.prop("supplier_items_weight_max").get()
	const maxheight = store.prop("supplier_items_height_max").get()
	const maxwdith = store.prop("supplier_items_wdith_max").get()
	const maxlength = store.prop("supplier_items_length_max").get()
	const maxcost = store.prop("supplier_locations_items_cost_max").get()

	const industry = store.prop("supplier_items_industry").get()
	const specifications = store.prop("supplier_items_specifications").get()
	const features = store.prop("supplier_items_features").get()
	const within = store.prop("supplier_locations_address").get()
	const currentlocation = store.prop("ValidatedWebAddress").get()
	const currentlocationlat = currentlocation.lat || 0
	const currentlocationlng = currentlocation.lng || 0
	const available = store.prop("supplier_locations_items_available").get()
	const categories = await sql`
		with items as (
			select 
				*
				,calculate_distance(
					supplier_locations.supplier_locations_gps[0]
					, supplier_locations.supplier_locations_gps[1]
					, ${currentlocationlat}
					, ${currentlocationlng}
					, 'K'
				)					
			from app.supplier_locations_items
			left join app.supplier_items using (supplier_items_id)
			left join app.supplier_locations using (supplier_locations_id)				
			left join app.suppliers on app.suppliers.supplier_id = app.supplier_items.supplier_id 
		)
		,fitems as (
			select 
				*
			from items
			where ( (${minweight} = 0 and ${maxweight} = 0) or ( supplier_items_weight > ${minweight} and supplier_items_weight < ${maxweight} ) ) 
			and ( (${minheight} = 0 and ${maxheight} = 0) or ( supplier_items_height > ${minheight} and supplier_items_height < ${maxheight} ) ) 
			and ( (${minwdith} = 0 and ${maxwdith} = 0) or ( supplier_items_wdith > ${minwdith} and supplier_items_wdith < ${maxwdith} ) ) 
			and ( (${minlength} = 0 and ${maxlength} = 0) or ( supplier_items_length > ${minlength} and supplier_items_length < ${maxlength} ) ) 
			and ( (${mincost} = 0 and ${maxcost} = 0) or ( supplier_locations_items_cost > ${mincost} and supplier_locations_items_cost < ${maxcost}) ) 
			
			and ( ${industry} = '' or supplier_items_industry = ANY( ${industry} ) ) 

			and ( ${features} = '' or supplier_items_features @> ${features})

			and ( ${supplier} = '' or lower(supplier_name) LIKE lower(('%' || ${supplier} || '%')))
			and ( ${name} = ''  or lower(supplier_items_name) LIKE lower(('%' || ${name} || '%'))) 
			and ( ${description} = ''  or lower(supplier_items_description) LIKE lower(('%' || ${description} || '%')))
			and ( ${specifications} = ''  or lower(supplier_items_specifications) LIKE lower(('%' || ${specifications} || '%')))
			
			and ( ${available} = supplier_locations_items_available )
			and ( calculate_distance < ${within} or ${within} = 0)
			and ( 
				calculate_distance < 100 
				or (${currentlocation.lat}::float = 0 and ${currentlocation.lng}::float = 0)
			)
		)
		select 
			supplier_items_category
			,supplier_items_icon
			,count(distinct supplier_items_id)
		from fitems AS r
		where ( ${search} = '' or lower(r::text) LIKE lower(('%' || ${search} || '%')))
		group by supplier_items_category, supplier_items_icon	
	`

	store.prop("itemCategories").update(() => categories)
	store.prop("loading").update(() => false)
}

async function searchItems(store: Store<any>, s = "") {
	const basket = store.prop("basket").get()
	const category = store.prop("category").get()
	const search = s

	const supplier = store.prop("supplier_name").get()
	const name = store.prop("supplier_items_name").get()
	const description = store.prop("supplier_items_description").get()

	const minweight = store.prop("supplier_items_weight_min").get()
	const minheight = store.prop("supplier_items_height_min").get()
	const minwdith = store.prop("supplier_items_wdith_min").get()
	const minlength = store.prop("supplier_items_length_min").get()
	const mincost = store.prop("supplier_locations_items_cost_min").get()

	const maxweight = store.prop("supplier_items_weight_max").get()
	const maxheight = store.prop("supplier_items_height_max").get()
	const maxwdith = store.prop("supplier_items_wdith_max").get()
	const maxlength = store.prop("supplier_items_length_max").get()
	const maxcost = store.prop("supplier_locations_items_cost_max").get()

	const industry = store.prop("supplier_items_industry").get()
	const specifications = store.prop("supplier_items_specifications").get()
	const features = store.prop("supplier_items_features").get()
	const within = store.prop("supplier_locations_address").get()
	const currentlocation = store.prop("ValidatedWebAddress").get()
	const currentlocationlat = currentlocation.lat || 0
	const currentlocationlng = currentlocation.lng || 0
	const available = store.prop("supplier_locations_items_available").get()
	const items = await sql`
		with checkout as (
			select * from json_to_recordset(${basket}::json )
			as x(
				"supplier_items_id" uuid,
				"supplier_locations_id" uuid,
				"quantity" real
			)												
		)
		,items as (
			select 
				* 
				,C.supplier_items_id as checked
				,SLI.supplier_items_id
				,SLI.supplier_locations_id
				,(case when C.quantity is null or C.quantity = 0 then 0 else C.quantity end) as quantity 
				,calculate_distance(
					SL.supplier_locations_gps[0]
					, SL.supplier_locations_gps[1]
					, ${currentlocationlat}
					, ${currentlocationlng}
					, 'K'
				)
			from app.supplier_locations_items SLI
			inner join app.supplier_items SI on  SI.supplier_items_id = SLI.supplier_items_id	
			inner join app.supplier_locations SL on SL.supplier_locations_id = SLI.supplier_locations_id	
			inner join app.suppliers S on SL.supplier_id = S.supplier_id
			left join checkout C on 
				SL.supplier_locations_id = C.supplier_locations_id	
				and SI.supplier_items_id = C.supplier_items_id					
		)
		select 
			*
		from items AS r
		where checked is not null
		or (( ${search} = '' or r::text LIKE ('%' || ${search} || '%') )
		and ( (${minweight} = 0 and ${maxweight} = 0) or ( supplier_items_weight > ${minweight} and supplier_items_weight < ${maxweight} ) ) 
		and ( (${minheight} = 0 and ${maxheight} = 0) or ( supplier_items_height > ${minheight} and supplier_items_height < ${maxheight} ) ) 
		and ( (${minwdith} = 0 and ${maxwdith} = 0) or ( supplier_items_wdith > ${minwdith} and supplier_items_wdith < ${maxwdith} ) ) 
		and ( (${minlength} = 0 and ${maxlength} = 0) or ( supplier_items_length > ${minlength} and supplier_items_length < ${maxlength} ) ) 
		and ( (${mincost} = 0 and ${maxcost} = 0) or ( supplier_locations_items_cost > ${mincost} and supplier_locations_items_cost < ${maxcost}) ) 
		
		and ( ${industry} = '' or supplier_items_industry = ANY( ${industry} ) ) 

		and ( ${features} = '' or supplier_items_features @> ${features})
		
		and ( ${supplier} = '' or lower(supplier_name) LIKE lower(('%' || ${supplier} || '%')))
		and ( ${name} = ''  or lower(supplier_items_name) LIKE lower(('%' || ${name} || '%'))) 
		and ( ${description} = ''  or lower(supplier_items_description) LIKE lower(('%' || ${description} || '%')))
		and ( ${specifications} = ''  or lower(supplier_items_specifications) LIKE lower(('%' || ${specifications} || '%')))
		
		and ( ${available} = supplier_locations_items_available )
		and ( supplier_items_category = ${category} or ${!category} = true)
		and ( calculate_distance < ${within} or ${within} = 0)
		and ( 
			calculate_distance < 100 
			or (${currentlocation.lat}::float = 0 and ${currentlocation.lng}::float = 0)
		))		
	`

	store.prop("items").update(() => items)
	store.prop("loading").update(() => false)
}

async function setMetaData(store: Store<any>, user: any) {
	if (user) {
		const AutoFillOptions: any[] = await sql`
			select 
				"order_contact_name"
				,"order_phone"
				,"order_address"
				,"order_description"
				,"order_notes"
			from app.orders
			limit 50
		`
		// NOTE WHEN YOU SEND SHERPA "AUSTRALIA" IN THE ADDRESS, THE ADDRESS VALIDATION IS AMBIGIOUS

		store
			.prop("addressOptions")
			.update(() => [...new Set(AutoFillOptions.map((a) => a.order_address))])
		store
			.prop("phoneOptions")
			.update(() => [...new Set(AutoFillOptions.map((a) => a.order_phone))])
		store
			.prop("nameOptions")
			.update(() => [
				...new Set(AutoFillOptions.map((a) => a.order_contact_name)),
			])

		store.prop("AutoFillOptions").update(() => AutoFillOptions)

		const [organization] =
			(await sql`select * from app.organizations where user_id = ${user.user_id}`) as Array<any>

		store.prop("organization").update(() => organization)

		await setIndustryOptions(store)
		await getBounds(store)

		return organization
	} else {
		store.prop("state").update(() => "")
		return { organization_id: null }
	}
}

async function injectSuppliers(store: Store<any>, organization: any) {
	await sql`delete from app.suppliers`

	const supplierdata = [
		{
			organization_id: organization.organization_id,
			supplier_name: "Tonys Tools",
			supplier_description: `One of Australia's largest for industrial tools`,
			items: [
				{
					supplier_items_name: "Xtorque X20OZ 20oz Solid Claw Hammer",
					supplier_items_description:
						"Xtorque has been committed to developing affordable yet long-lasting equipment and accessories since it's inception. The Xtorque 20OZ hammer is the ideal range for all Domestic applications. Where other tools may fail in affordability, the Xtorque 20OZ hammer will satisfy most applications.",
					supplier_items_cost: 10.74,
					supplier_items_batch: 1,
					supplier_items_batch_cost: 10.74,
					supplier_items_supply_time: 0,
					supplier_items_available: true,
					supplier_items_stock: 100,
					supplier_items_uom: "Each",
					supplier_items_minimum_order: 1,
					supplier_items_category: "Hammers",
					supplier_items_industry: "Building",
					supplier_items_icon: "assets/General Hardware.png",
					// "https://sydneytools.com.au/assets/images/products/7/4/9/0/7490/8C1145E489F4D4045922B82360FC9A948AB52550C8667981643DAF5404CBA9E3.jpeg",
					supplier_items_image:
						"https://sydneytools.com.au/assets/images/products/7/4/9/0/7490/8C1145E489F4D4045922B82360FC9A948AB52550C8667981643DAF5404CBA9E3.jpeg",

					supplier_items_weight: 2,
					supplier_items_height: 30,
					supplier_items_wdith: 120,
					supplier_items_length: 300,
					supplier_items_serial: "W114031",
					supplier_items_specifications: "",
					supplier_items_features: [
						"Double Impact Steel",
						"Anti Vibration",
						"Ultra Comfort Handle",
					],
				},
				{
					supplier_items_name: "Toledo 321039 170mm Mini Precision Hammer",
					supplier_items_description:
						"Suitable for all panel and trim work. The lightweight and compact design allows for use in confined areas. Fitted with one steel face and one rubber face that delivers precise force. The handle contains a built in pry bar to remove trim without damage",
					supplier_items_cost: 11.95,
					supplier_items_batch: 1,
					supplier_items_batch_cost: 11.95,
					supplier_items_supply_time: 3,
					supplier_items_available: true,
					supplier_items_stock: 75,
					supplier_items_uom: "Each",
					supplier_items_minimum_order: 1,
					supplier_items_category: "Fasteners and Fixings",
					supplier_items_industry: "Automotive",
					supplier_items_icon: "assets/Fasteners _ fixtings.png",
					// "https://sydneytools.com.au/assets/images/products/1/4/3/6/14364/74F9B966B0CF49D34D2DD479375A3B80241341DD44E42005A1C1C4011DADC50B.jpeg",
					supplier_items_image:
						"https://sydneytools.com.au/assets/images/products/1/4/3/6/14364/74F9B966B0CF49D34D2DD479375A3B80241341DD44E42005A1C1C4011DADC50B.jpeg",

					supplier_items_weight: 2,
					supplier_items_height: 15,
					supplier_items_wdith: 120,
					supplier_items_length: 60,
					supplier_items_serial: "321039",
					supplier_items_specifications: "",
					supplier_items_features: ["Suitable for all panel and trim work"],
				},
			],
			locations: [
				{
					supplier_locations_monday_operating_hours: "07:00 - 17:30",
					supplier_locations_tuesday_operating_hours: "07:00 - 17:30",
					supplier_locations_wednesday_operating_hours: "07:00 - 17:30",
					supplier_locations_thursday_operating_hours: "07:00 - 20:00",
					supplier_locations_friday_operating_hours: "07:00 - 17:30",
					supplier_locations_saturday_operating_hours: "08:00 - 16:00",
					supplier_locations_sunday_operating_hours: "08:00 - 16:00",
					supplier_locations_public_holiday_operating_hours: "Closed",
					supplier_locations_phone: "0413101531",
					supplier_locations_email: null,
					supplier_locations_status: true,
					supplier_locations_gps: "(-33.92025804557715, 151.08889894232917)",
					supplier_locations_address: "4 Kent St Belmore NSW 2192",
					supplier_locations_instructions:
						"Pick up from 2nd Level, office at the end of the hallway",
				},
				{
					supplier_locations_monday_operating_hours: "07:00 - 17:30",
					supplier_locations_tuesday_operating_hours: "07:00 - 17:30",
					supplier_locations_wednesday_operating_hours: "07:00 - 17:30",
					supplier_locations_thursday_operating_hours: "07:00 - 20:00",
					supplier_locations_friday_operating_hours: "07:00 - 17:30",
					supplier_locations_saturday_operating_hours: "08:00 - 16:00",
					supplier_locations_sunday_operating_hours: "08:00 - 16:00",
					supplier_locations_public_holiday_operating_hours: "Closed",
					supplier_locations_phone: "0403949106",
					supplier_locations_email: null,
					supplier_locations_status: false,
					supplier_locations_gps: "(-33.843081169168805, 151.20459296932123)",
					supplier_locations_address:
						"L7 182 Blues Point Road, North Sydney NSW",
					supplier_locations_instructions: "Pick up from level 7",
				},
				{
					supplier_locations_monday_operating_hours: "07:00 - 17:30",
					supplier_locations_tuesday_operating_hours: "07:00 - 17:30",
					supplier_locations_wednesday_operating_hours: "07:00 - 17:30",
					supplier_locations_thursday_operating_hours: "07:00 - 20:00",
					supplier_locations_friday_operating_hours: "07:00 - 17:30",
					supplier_locations_saturday_operating_hours: "08:00 - 16:00",
					supplier_locations_sunday_operating_hours: "08:00 - 16:00",
					supplier_locations_public_holiday_operating_hours: "Closed",
					supplier_locations_phone: "0421763259",
					supplier_locations_email: null,
					supplier_locations_status: false,
					supplier_locations_gps: "(-33.93284536750745, 151.11783210835213)",
					supplier_locations_address: "14 Attunga Avenue Earlwood NSW 2206",
					supplier_locations_instructions: "Pick Up from Front veranda",
				},
			],
		},
	]

	return m
		.request("/api/sql/supplier", {
			method: "POST",
			body: { query: [], args: [], tag: "sql", data: supplierdata },
			headers: {
				Authorization: "Bearer " + "",
			},
		})
		.then(async () => {
			await setIndustryOptions(store)
			await getBounds(store)
			await searchCategories(store)
			return true
		})
}

async function initialize(store: Store<any>) {
	store.prop("userload").update(() => true)
	try {
		const [user] = (await sql`

				select 
					user_id
					, email
					, name
					, picture
					, organization_id					
					, to_json(array_remove(array_agg( distinct UA.* ), null)) as users_addresses					
				from app.users U
				left join app.users_addresses UA using(user_id)
				group by U.user_id, U.email, U.name, U.picture, U.organization_id	
		`) as Array<any>

		store.prop("user").update(() => user)
		const organization = await setMetaData(store, user)

		if (store.prop("addressOptions").get().length > 0) {
			store.prop("state").update(() => "Search")
		}

		// REMOVE FROM PROD
		await injectSuppliers(store, organization)

		await getLocation(store, store.prop("ValidatedWebAddress"))

		store.prop("userload").update(() => false)

		if (user) {
			store.prop("state").update(() => "Search")
		}
	} catch (e) {
		store.prop("state").update(() => "")
		store.prop("userload").update(() => false)
		return e
	}
}

async function getLocation(store: Store<any>, address: Store<any>) {
	const processing = store.prop("gettinglocation")
	const setlatlong = (s: any) => {
		address.update(() =>
			s
				? {
						lat: Number(s.coords.latitude),
						lng: Number(s.coords.longitude),
				  }
				: {}
		)
		processing.update(() => false)
		return false
	}

	// eslint-disable-next-line no-undef
	if (!navigator.geolocation) {
		return true
	} else {
		// eslint-disable-next-line no-undef
		processing.update(() => true)
		return navigator.geolocation.getCurrentPosition(setlatlong, setlatlong)
	}
}

function sql(query: TemplateStringsArray, ...args: any[]): Promise<any[]> {
	const token = ""
	return m.request<any[]>("/api/sql/select", {
		method: "POST",
		body: { query, args, tag: "sql" },
		headers: {
			Authorization: "Bearer " + token,
		},
	})
}

function here(
	address = "" as string,
	{ lat, lng }: { lat: number; lng: number }
): Promise<any[]> {
	return m
		.request("/api/here/address", {
			method: "POST",
			body: {
				address,
				types: "houseNumber",
				lat,
				lng,
			},
		})
		.then(
			(res: any) => {
				// console.log("HERE", res)
				return res
			},
			(e: any) => {
				console.log(e.response)
				return e.response.error == "Unauthorized call"
			}
		)
}

function sherpa({
	endpoint,
	delivery_address,
	items,
	user_id,
	deliveryDetails,
	group_payment_id,
	paymentid,
}: {
	endpoint: string
	delivery_address: string
	items: Array<any>
	user_id: string
	deliveryDetails: object
	group_payment_id: string
	paymentid: string | null
}) {
	const data = {
		delivery_address,
		items,
		user_id,
		group_payment_id,
		paymentid,
		deliveryDetails,
		tag: "sherpa",
	}

	const token = ""
	return m
		.request("/api/sherpa/" + endpoint, {
			method: "POST",
			body: { data },
			headers: {
				Authorization: "Bearer " + token,
			},
		})
		.then(
			(res) => {
				return res
			},
			(e) => {
				console.log(e.response)
				return e.response.error == "Unauthorized call"
			}
		) as any
}

export {
	sql,
	initialize,
	sherpa,
	searchCategories,
	searchItems,
	here,
	getLocation,
	setMetaData,
}
