import m, * as bacta from "bacta"
import { CardAttrs, Card } from "../sl/card.js"
// import * as Store from "jaforbes-s/store.js";

type BaseCardAttrs = {
	sl?: Partial<CardAttrs>
} & bacta.Attributes

export type MainCardAttrs = BaseCardAttrs

export const HarthCard: bacta.ClosureComponent<MainCardAttrs> = () => ({
	view: ({ attrs: { sl = {}, ...attrs }, children = [] }) => {
		return m(
			Card,
			{
				...sl,
				onclick: attrs.onclick,
			},
			children
		)
	},
})
