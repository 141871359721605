import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/components/select/select.js"
import css from "../../main.module.css"
import m, { BactaComponent } from "bacta"
export type SelectAttrs = {
	name?: string
	value?: string | Array<string>
	placeholder?: string
	disabled?: boolean
	size?: "small" | "medium" | "large"
	filled?: boolean
	label?: string
	placement?: "top" | "bottom"
	helpText?: string
	required?: boolean
	defaultValue?: string
	pill?: boolean
	hoist?: boolean
	clearable?: boolean
	maxTagsVisible?: number
	multiple?: boolean
	class?: string

	"onsl-change"?(event: InputEvent): void
}
export const Select: BactaComponent<SelectAttrs> = () => ({
	view: ({ attrs = {}, children }) =>
		m("sl-select." + css[attrs.class || ""], attrs, children || []),
})
