import m, * as bacta from "bacta"
import { Dialog, DialogAttrs } from "../sl/dialog.js"

export type Attrs = {
	open: boolean | string | null
}

export type BaseAttrs = {
	sl?: Partial<DialogAttrs>
}

export type MainAttrs = BaseAttrs & Attrs

export const HarthDialog: bacta.ClosureComponent<MainAttrs> = () => ({
	view: ({ attrs: { sl = {}, ...attrs }, children = [] }) => {
		return m(
			Dialog,
			{
				...sl,
				open: attrs.open ? true : false,
			},
			children
		)
	},
})
