import m, * as bacta from "bacta"
import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/themes/dark.css"
import "@shoelace-style/shoelace/dist/components/menu-item/menu-item.js"
import "@shoelace-style/shoelace/dist/components/menu/menu.js"
import css from "../../main.module.css"
export type MenuItemAttrs = {
	value?: string
	checked?: boolean
	disabled?: boolean
	class?: string
}

export type MenuAttrs = {
	"onsl-select"?(event: InputEvent): void
}

export const MenuItem: bacta.ClosureComponent<MenuItemAttrs> = () => ({
	view: ({ attrs = {}, children = [] }) =>
		m("sl-menu-item." + css[attrs.class || ""], attrs, children),
})

export const Menu: bacta.ClosureComponent<MenuAttrs> = () => ({
	view: ({ attrs = {}, children = [] }) => m("sl-menu", attrs, children),
})
