import m, * as bacta from "bacta"
import { Icon, IconButton, IconAttrs, IconButtonAttrs } from "../sl/icon.js"

export type Attrs = {
	size?: string
}

export type BaseAttrs = {
	sl?: Partial<IconAttrs & IconButtonAttrs>
}

export type MainAttrs = BaseAttrs & Attrs

export const HarthIcon: bacta.ClosureComponent<MainAttrs> = () => ({
	view: ({ attrs: { sl = {}, ...attrs } }) => {
		return m(Icon, {
			style: {
				[attrs.size ? "font-size" : ""]: attrs.size,
			},
			...sl,
		})
	},
})

export const HarthIconButton: bacta.ClosureComponent<
	MainAttrs & IconButtonAttrs
> = () => ({
	view: ({ attrs: { sl = {}, ...attrs }, children = [] }) => {
		return m(
			IconButton,
			{
				style: {
					[attrs.size ? "font-size" : ""]: attrs.size,
				},
				...sl,
			},
			children
		)
	},
})
