import m, * as bacta from "bacta"
import css from "../../main.module.css"

import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/components/card/card.js"

export type CardAttrs = {
	class?: string

	"onclick"?(event: InputEvent): void
}

export const Card: bacta.ClosureComponent<CardAttrs> = () => {
	return {
		view: ({ attrs, children }) => {
			return m("sl-card." + css[attrs.class || ""], attrs, children)
		},
	}
}
