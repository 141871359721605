import m, * as bacta from "bacta"

import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/components/progress-bar/progress-bar.js"

export type ProgressBarAttrs = {
	value: number
	indeterminate?: boolean
	label?: string
	slot?: "footer" | "header" | "label"
}

export const ProgressBar: bacta.ClosureComponent<ProgressBarAttrs> = () => {
	return {
		view: ({ attrs, children }) => {
			return m("sl-progress-bar", attrs, children)
		},
	}
}
