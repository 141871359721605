import m, * as bacta from "bacta"
import { ProgressBarAttrs, ProgressBar } from "../sl/progress-bar.js"

type BaseProgressBarAttrs = {
	sl?: Partial<ProgressBarAttrs>
}

export type MainProgressBarAttrs = BaseProgressBarAttrs

export const HarthProgressBar: bacta.ClosureComponent<
	MainProgressBarAttrs
> = () => ({
	view: ({ attrs: { sl = {} }, children = [] }) => {
		return m(
			ProgressBar,
			{
				...(sl as ProgressBarAttrs),
			},
			children
		)
	},
})
