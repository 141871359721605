import m, * as bacta from "bacta"
import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/components/textarea/textarea.js"

export type TextAreaAttrs = {
	type: "number" | "text"
	name?: string
	value?: string
	size?: "small" | "medium" | "large"
	filled?: boolean
	label?: string
	helpText?: string
	placeholder?: string
	rows?: number
	resize?: "none" | "vertical" | "auto"
	disabled?: boolean
	readonly?: boolean
	minlength?: number
	maxlength?: number
	required?: boolean
	defaultValue?: string
	autocorrect?: string
	autocomplete?: string
	autofocus?: boolean
	enterkeyhint?:
		| "enter"
		| "done"
		| "go"
		| "next"
		| "previous"
		| "search"
		| "send"
	spellcheck?: boolean
	inputmode?:
		| "none"
		| "text"
		| "decimal"
		| "numeric"
		| "tel"
		| "search"
		| "email"
		| "url"

	"onsl-input"?(event: InputEvent): void
}
export const TextArea: bacta.ClosureComponent<TextAreaAttrs> = () => ({
	view: ({ attrs = {} }) => m("sl-textarea", attrs),
})
