import m, { BactaComponent } from "bacta"
import { Avatar, AvatarAttrs } from "../sl/avatar.js"
import { HarthButton, MainButtonAttrs, BaseButtonAttrs } from "./button.js"

export type Attrs = {
	size?: string
}

export type BaseAttrs = {
	sl?: Partial<AvatarAttrs>
	slButton?: BaseButtonAttrs["sl"]
}

export type MainAttrs<T> = BaseAttrs & Attrs & MainButtonAttrs<T>

export const HarthAvatar: BactaComponent<MainAttrs<any>> =
	() =>
	({ attrs: { sl = {}, slButton, query, ...attrs } }) => {
		const avatar = m(Avatar, {
			style: {
				// todo-james this should probably be { ...(attrs.size && { '--size': attrs.size }) }
				[attrs.size ? "--size" : ""]: attrs.size,
			},
			...sl,
		})
		return slButton && slButton.value
			? m(HarthButton, { query, sl: slButton }, avatar)
			: avatar
	}
