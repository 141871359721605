import m, * as bacta from "bacta"
import { Drawer, DrawerAttrs } from "../sl/drawer.js"

export type Attrs = {
	query: bacta.Store<string> | bacta.Store<boolean>
	children?: bacta.Children
}

export type BaseAttrs = {
	sl?: Partial<DrawerAttrs>
}

export type MainAttrs = BaseAttrs & Attrs

export const HarthDrawer: bacta.ClosureComponent<MainAttrs> = () => ({
	view: ({ attrs: { sl = {}, ...attrs } }) => {
		return m(
			Drawer,
			{
				...sl,
				open: attrs.query.get() ? true : false,
			},
			attrs.children
		)
	},
})
