import m, { BactaComponent } from "bacta"

import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/components/button/button.js"

import css from "../../main.module.css"

export type ButtonAttrs = {
	"onclick"?(event: InputEvent): any
	variant?:
		| "default"
		| "primary"
		| "success"
		| "neutral"
		| "warning"
		| "danger"
		| "text"
	size?: "small" | "medium" | "large"
	disabled?: boolean
	loading?: boolean
	outline?: boolean
	selector?: string
	download?: string
	href?: string
	target?: "_blank" | "_parent" | "_self" | "_top"
	name?: string
	value?: string | number | boolean | object
	slot?: string
	pill?: boolean

	id?: string
	class?: string
}

export const Button: BactaComponent<ButtonAttrs> = () => {
	return ({ attrs, children }) => {
		return m("sl-button." + css[attrs.class || ""], attrs, children)
	}
}
