import m from "bacta"

import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/components/avatar/avatar.js"

export type AvatarAttrs = {
	"onclick"?(event: InputEvent): void
	image?: string
	shape?: "circle" | "square" | "rounded"
	loading?: "eager" | "lazy"
	label?: string
	initials?: string
	value?: string
} & m.Attributes

export const Avatar: m.ClosureComponent<AvatarAttrs> = () => {
	return {
		view: ({ attrs, children }) => {
			return m("sl-avatar", attrs, children)
		},
	}
}
