/* eslint-disable no-mixed-spaces-and-tabs */
import m, * as bacta from "bacta"
import * as L from "leaflet"
import legacyCSS from "./main.module.css"
import { HarthSelect } from "./components/harth/select"
import { HarthInput } from "./components/harth/input"
import { HarthTextArea } from "./components/harth/textarea"
import { HarthButton } from "./components/harth/button"
import { HarthIcon, HarthIconButton } from "./components/harth/icon"
import { HarthSpinner } from "./components/harth/spinner"
import { HarthStripe } from "./components/harth/stripe"
import { HarthMenu } from "./components/harth/menu"
import {
	getLocation,
	initialize,
	searchCategories,
	searchItems,
	sherpa,
	sql,
	here,
	setMetaData,
} from "./utils/signed-query"
import { HarthTabGroup } from "./components/harth/tab"
import {
	HarthMap,
	dotMarker,
	LocationMarker,
	outlineddotMarker,
	craneMarker,
	toolsMarker,
	Map,
} from "./components/harth/map"
import { HarthCard } from "./components/harth/card"
import { HarthProgressBar } from "./components/harth/progress-bar"
import moment from "moment"
import { State, useStore, FormalAddress } from "./store"
import { money, pretty } from "./utils/regexes"
import { deliveryType, gpsType, itemType, orderType } from "./types"
import * as R from "ramda"
import { HarthDialog } from "./components/harth/dialog"
import { HarthDrawer } from "./components/harth/drawer"
import { HarthRange } from "./components/harth/range"
import { HarthAvatar } from "./components/harth/avatar"
import { privacyPolicy } from "./privacy-policy"

const css = bacta.css

const dologo = "assets/DropOff-Logo-grey-transparent-tight.png"
const dologopin = "assets/DropOff-Logo-grey-transparent-tight-pin.png"
const orangetick = "assets/orange-tick-outlined.png"
const websiteHome = "assets/website-landing169CS.png"

const pp = m.trust(privacyPolicy)

type SearchK = keyof State

function debounce(f: (...args: any[]) => void | Promise<void>, ms = 250) {
	let id: string | number | NodeJS.Timeout | undefined
	return function (...args: any[]) {
		clearTimeout(id)
		id = setTimeout(async function () {
			await f(...args)
		}, ms)
	}
}

function updateCheckout() {
	const { store } = useStore()
	const checkoutitems =
		store.prop("state").get() == "Checkout"
			? store.prop("basket").get()
			: store.prop("items").unnest().where`i => i.quantity > 0`.getAll()

	store.prop("basket").update(() => checkoutitems)
}

document.body.classList.add(legacyCSS.body)

function autocomplete(
	v: any,
	options: Array<string> = [],
	query: bacta.Store<string> | bacta.Store<string[]>,
	isnew: bacta.Store<boolean>,
	help: string,
	boxtype: string,
	width: string,
	required: boolean,
	customInput?: any
) {
	return m(
		".app.",
		css`
			& {
				width: ${width};
			}
		`,

		!isnew.get() && options.length
			? m(HarthSelect, {
					query: query,
					options: options,
					sl: {
						multiple: false,
						placement: "bottom",
						size: "medium",
						hoist: true,
						helpText: help,
						clearable: false,
						class: "borderBottom",
					},
			  })
			: customInput
			? customInput(v)
			: boxtype == "input"
			? m(HarthInput, {
					query: query as bacta.Store<string>,
					type: "text",
					sl: {
						size: "medium",
						helpText: help,
						class: "borderBottom",
						clearable: true,
						required: required,
					},
			  })
			: m(HarthTextArea, {
					query: query as bacta.Store<string>,
					type: "text",
					sl: {
						placeholder: "",
						size: "small",
						helpText: help,
					},
			  }),

		options.length
			? m(
					HarthButton,
					{
						query: isnew,
						sl: {
							size: "medium",
							variant: "default",
							outline: false,
							value: isnew.get() ? false : true,
							class: "whitebackground",
						},
					},
					m(HarthIcon, {
						sl: {
							name: isnew.get() ? "arrow-return-left" : "pencil",
							slot: "prefix",
							style: { "font-size": "24px" },
						},
					})
			  )
			: null
	)
}

function buttonIcon(
	query: any,
	icon: string,
	value: string,
	label: string,
	options: string[]
) {
	const q = query.get()
	return m(
		HarthButton,
		{
			query: query,
			sl: {
				size: "small",
				variant: "text",
				outline: false,
				value: value,
				class: q == value ? "whitebackground" : "",
				disabled: options.filter((o) => value == o).length == 0,
			},
		},
		m(".app." + legacyCSS.spreadRowsCenter, [
			m(HarthIcon, {
				sl: {
					name: icon,
					slot: "prefix",
					style: {
						"font-size": q == value ? "48px" : "36px",
						"font-weight": q == value ? "bold" : "",
					},
				},
			}),
			m(".app." + legacyCSS.centeredHeader, label),
		])
	)
}

function addressMap(v: bacta.Vnode) {
	const { store, l, s } = useStore()
	const map$ = v.useStore("address-map", v.useStream<Map>())
	v.useEffect({ name: "map-update" }, function* () {
		const [map, FormalAddress, loading] = (yield [
			map$,
			store.prop("FormalAddress"),
			l,
		]) as [Map, FormalAddress, any]
		const icon = dotMarker
		store
			.prop("deliveryToMarker")
			.get()
			?.map((marker: any) => marker.remove())

		// L.icon({
		// 	iconUrl: "/marker-icon.png",
		// 	shadowUrl: "/marker-shadow.png",
		// })
		if (
			FormalAddress &&
			FormalAddress.position &&
			!loading &&
			s.get() == "Checkout"
			//( || (s.get() == "Home" && store.prop("orders").get().length > 0))
		) {
			map.invalidateSize()
			if (map.setView) {
				const items = store
					.prop("basket")
					.get()
					.filter((a) => a.supplier_locations_gps)
					.map((a) => {
						const point = a.supplier_locations_gps
							.replaceAll("(", "")
							.replaceAll(")", "")
							.split(",")
							.map((p) => Number(p))
						return point
					})

				if (FormalAddress.position.lat && FormalAddress.position.lng) {
					items.unshift([
						Number(FormalAddress.position.lat),
						Number(FormalAddress.position.lng),
					])

					map.setView(
						[
							Number(FormalAddress.position.lat),
							Number(FormalAddress.position.lng),
						],
						16,
						{
							animate: true,
						}
					)
				}

				store.prop("deliveryToMarker").update(() =>
					items.map((dp, i) => {
						const marker = L.marker([Number(dp[0]), Number(dp[1])], {
							icon: i == 0 ? LocationMarker : icon,
						})
						marker.addTo(map)
						return marker
					})
				)

				if (
					items.length >= 2 &&
					items.every((a) => a.length >= 2 && a[0] && a[1])
				) {
					map.flyToBounds(items as [[number, number]], {
						animate: true,
						padding: [32, 32],
						maxZoom: 17,
						duration: 2,
					})
				}
			}

			m.redraw()
		}
	})

	return m(
		".app.map-wrapper",
		css`
			& {
				opacity: ${() =>
					store
						.prop("FormalAddress")
						.prop("position")
						.get.map((x) => (x ? 1 : 0.4))};
				transition: opacity 1s;
				pointer-events: ${() =>
					store
						.prop("FormalAddress")
						.prop("position")
						.get.map((x) => (x ? "inherit" : "none"))};

				border-radius: var(--border-radius);
				overflow: hidden;
				box-shadow: 0px 4px 5px -2px #c1c1c1;
			}
		`,
		m(HarthMap, { id: "address-map", map: map$ })
	)
}

function newNearAddress(v: bacta.Vnode, renderMap: boolean) {
	const {
		store,
		FormalAddress,
		nearAddressOptions,
		ValidatedAddress,
		addressError,
		l,
	} = useStore()

	const map$ = v.useStore("address-map", v.useStream<Map>())
	const address = true

	v.useEffect({ name: "map-update" }, function* () {
		const [map, FormalAddress] = (yield [
			map$,
			store.prop("FormalAddress"),
			store.prop("nearAddressOptions"),
			store.prop("ValidatedWebAddress"),
		]) as [Map, FormalAddress]

		const icon = LocationMarker
		const marker = L.marker(
			[FormalAddress.position.lat, FormalAddress.position.lng],
			{
				icon,
			}
		)
		marker.addTo(map)

		map.setView(
			[Number(FormalAddress.position.lat), Number(FormalAddress.position.lng)],
			17,
			{
				animate: true,
			}
		)

		map.fitBounds(
			[
				[
					Number(FormalAddress.position.lat),
					Number(FormalAddress.position.lng),
				],
			],
			{
				animate: true,
				padding: [25, 25],
				maxZoom: 17,
			}
		)
	})

	// https://github.com/JAForbes/bacta/issues/4
	v.useEffect({ name: "redraw" }, function* () {
		yield [FormalAddress.get, nearAddressOptions.get, ValidatedAddress.get]
		m.redraw()
	})

	return m(
		".app." + legacyCSS.spreadRows,
		address
			? () =>
					bacta.Stream.merge([
						store.prop("deliveryTo").get,
						addressError.get,
						l.get,
					]).map(([]) =>
						m(HarthInput, {
							query: store.prop("deliveryTo"),
							type: "text",
							sl: {
								size: "medium",
								helpText: "Address",
								class: "borderBottom",
								clearable: true,
								disabled: l.get(),
							},
						})
					)
			: null,

		address
			? () =>
					m(
						".app.popup-wrapper" + legacyCSS.tightMenuItem,
						css`
							& {
								position: relative;
							}
							& > * {
								position: absolute;
								/* Leaflet :/ */
								z-index: 1000000;
								width: 100%;
							}
						`,

						bacta.Stream.merge([
							store.prop("nearAddressOptions").get as any,
							store.prop("ValidatedAddress").get as any,
							store.prop("AddressError").get as any,
						]).map(([,]) =>
							nearAddressOptions.get().length >= 1 &&
							(store.prop("deliveryTo").get() !=
								store.prop("ValidatedAddress").get() ||
								!store.prop("deliveryTo").get()) &&
							FormalAddress.get().title != store.prop("deliveryTo").get()
								? m(HarthMenu, {
										query: store.prop("FormalAddress") as any,
										options: store
											.prop("nearAddressOptions")
											.get()
											.slice(0, 10),
										nameProp: "title",
										class: "tightMenuItem",
								  })
								: null
						)
					)
			: null

		// !renderMap || !store.prop("FormalAddress").get().title
		// 	? null
		// 	: () => store.prop("FormalAddress").get.map(() => addressMap(v))
	)
}

function deliveryInputs(v: bacta.Vnode) {
	const { store, l, addressError, ValidatedAddress } = useStore()

	const timeframe = store.prop("timeframe")
	const vehicle = store.prop("vehicle")
	const deliveryTo = store.prop("deliveryTo")
	const addressOptions = store.prop("addressOptions")
	const phoneOptions = store.prop("phoneOptions")
	const nameOptions = store.prop("nameOptions")
	const avo = store.prop("vehilceOptions")
	const checkoutindex = store.prop("checkoutindex").get()
	const availableTimeFrames = store.prop("availableTimeFrames").get()
	const atf = availableTimeFrames
		.filter((af) =>
			checkoutindex.every((co: any) => co[af.replaceAll(" ", "")] > 0)
		)
		.map((af) => af.replaceAll(" ", ""))

	return m(
		".app." + legacyCSS.spreadRowsBigGap,

		() =>
			bacta.Stream.merge([
				store.prop("newAddress").get as any,
				store.prop("deliveryTo").get as any,
				store.prop("AutoFillOptions").get as any,
				store.prop("FormalAddress").get as any,
				store.prop("newAddress").get as any,
				addressError.get as any,
				l.get as any,
			]).map(([]) =>
				m(
					".app." + legacyCSS.spreadRowsSmallGap,
					// m(
					// 	".app.",
					// 	css`
					// 		& {
					// 			display: flex;
					// 			justify-content: flex-end;
					// 		}
					// 	`,
					// 	m(
					// 		HarthButton,
					// 		{
					// 			query: store.prop("deliveryTo"),
					// 			postop: () =>
					// 				getLocation(store, store.prop("ValidatedWebAddress")),
					// 			sl: {
					// 				size: "medium",
					// 				variant: "text",
					// 				outline: false,
					// 				value: "",
					// 				class: "whitebackground",
					// 				disabled: store.prop("gettinglocation").get(),
					// 			},
					// 		},
					// 		store.prop("gettinglocation").get()
					// 			? m(HarthSpinner, {})
					// 			: m(HarthIcon, {
					// 					sl: {
					// 						// name: "geo-alt",
					// 						// name: "geo",
					// 						name: "crosshair",
					// 						slot: "suffix",
					// 						style: {
					// 							"font-size": "28px",
					// 							"font-weight": "bold",
					// 						},
					// 					},
					// 			  }),
					// 		`Use Current Location`
					// 	)
					// ),

					addressMap(v),

					() =>
						l.get.map((loading) =>
							m(
								".app.",
								css`
									& {
										display: flex;
										justify-content: center;
									}
								`,
								loading
									? m(
											HarthSpinner,
											css`
												& {
													padding-top: 0.75rem;
												}
											`
									  )
									: addressError.get()
									? m(
											".app." + legacyCSS.centeredTitles,
											css`
												& {
													padding-top: 0.75rem;
													color: red;
												}
											`,
											store.prop("FormalAddress").get()?.resultType &&
												store.prop("FormalAddress").get().resultType !=
													"houseNumber" &&
												addressError.get() !=
													"We can only deliver within 100 Km"
												? m(".app.", "Please specify street number")
												: null,
											m(".app.", addressError.get())
									  )
									: !store.prop("deliveryTo").get()
									? null
									: m("img", {
											src: orangetick,
											style: {
												"object-fit": "scale-down",
												width: "6%",
												height: "6%",
												"align-items": "center",
												"padding-top": "0.75rem",
											},
									  })
							)
						),

					autocomplete(
						v,
						[],
						deliveryTo,
						store.prop("newAddress"),
						"Address",
						"input",
						"100%",
						true,
						() => newNearAddress(v, false)
					)
				)
			),

		m(
			".app." + legacyCSS.spreadColumnsSpaceAroundAlignStart,
			{ style: { "min-height": "6em" } },
			["Now", "Within2hours", "Within4hours", "SameDay"].map((b) =>
				buttonIcon(
					timeframe,
					"clock-history",
					b,
					b == "Now"
						? "Now"
						: b == "Within2hours"
						? "in 2 Hour"
						: b == "Within4hours"
						? "in 4 Hour"
						: "Today",
					atf
				)
			)
		)

		// m(
		// 	".app." + legacyCSS.spreadColumnsSpaceAroundAlignStart,
		// 	{ style: { "min-height": "6em" } },
		// 	["1", "2", "4"].map((b) =>
		// 		buttonIcon(
		// 			vehicle,
		// 			b == "2" ? "bicycle" : b == "1" ? "car-front" : "truck",
		// 			b,
		// 			b == "2" ? "Bike" : b == "1" ? "Car" : "Van",
		// 			avo.get()
		// 		)
		// 	)
		// )

		// m(".app." + legacyCSS.spreadColumnsSpaceBetween, [
		// 	autocomplete(
		//     v
		// 		, nameOptions
		// 		, store.prop("order_contact_name")
		// 		, store.prop("newName")
		// 		, "Site Contact"
		// 		, 'input'
		// 		, false
		// 	),

		// 	autocomplete(
		// 		v
		// 		, phoneOptions
		// 		, store.prop("order_phone")
		// 		, store.prop("newPhone")
		// 		, "Phone"
		// 		, 'input'
		// 		, false
		// 	)
		// ]),
		// ])
	)
}

function AppTray(v: any) {
	const { store, l } = useStore()
	const state = store.prop("state")
	const basket = store.prop("basket")
	return {
		view: () =>
			m(
				".app." + legacyCSS.content,
				m(HarthTabGroup, {
					query: state,
					sl: {
						class: "navbarFixed",
						placement: "bottom",
						activation: "manual",
					},
					tabs: [
						{
							name: "Home",
							icon: m(HarthIcon, { sl: { name: "house" }, size: "2rem" }),
							view: m(HomeView, { v }),
							active: false,
							disabled: l.get(),
						},
						{
							name: "Search",
							icon: m(HarthIcon, {
								sl: { name: "search" },
								size: "2rem",
							}),
							view: m(SearchView, {}),
							active: true,
							disabled: l.get(),
						},
						{
							name: "Checkout",
							icon: m(HarthIcon, {
								sl: { name: "cart2" },
								size: "2rem",
							}),
							view: m(CheckoutView, { v }),
							active: false,
							disabled: basket.get().length == 0 || l.get(),
						},
						{
							name: "Settings",
							icon: m(HarthIcon, {
								sl: { name: "gear" || "boxes" },
								size: "2rem",
							}),
							view: m(SettingsView, {}),
							active: false,
							disabled: true || l.get(),
						},
					],
				})
			),
	}
}

function AppHeader() {
	const { store, l } = useStore()
	const drawOpen = store.prop("drawOpen")
	const industryCategories = store.prop("industryCategories").get()
	const withinkm = store.prop("supplier_locations_address")

	const numberValues = [
		"supplier_items_weight",
		"supplier_items_height",
		"supplier_items_wdith",
		"supplier_items_length",
		"supplier_locations_items_cost",
	].map((a) => ({
		minbound: ("min_" + a) as SearchK,
		maxbound: ("max_" + a) as SearchK,
		min: (a + "_min") as SearchK,
		max: (a + "_max") as SearchK,
		type: (a.indexOf("_date") > -1 ? "date" : "number") as "date" | "number",
	}))

	const searchValues: { prop: SearchK; help: string }[] = [
		{ prop: "supplier_name", help: "Store" },
		{ prop: "supplier_items_name", help: "Name" },
		{ prop: "supplier_items_description", help: "Description" },
		{ prop: "supplier_items_category", help: "Category" },
		{ prop: "supplier_items_specifications", help: "Specifications" },
		{ prop: "supplier_items_features", help: "Features" },
		{
			prop: "supplier_locations_address",
			help: "Within " + withinkm.get() + " Km",
		},
	]

	return [
		m(
			".app." + legacyCSS.appHeader,
			m(".app." + legacyCSS.spreadColumnsSpaceBetween, {}, [
				m("img", {
					src: dologopin,
					style: {
						"object-fit": "scale-down",
						width: "15%",
						height: "15%",
					},
				}),

				m(HarthDrawer, {
					query: drawOpen,
					sl: {
						placement: "start",
						contained: true,
						noHeader: true,
					},
					children: [
						m(".app." + legacyCSS.spreadRowsBigGap, [
							m(
								HarthButton,
								{
									query: drawOpen,
									sl: {
										size: "medium",
										variant: "primary",
										outline: true,
										value: false,
									},
									// slot: "footer",
								},
								"Close"
							),

							centeredSpinner(
								l.get(),
								"centeredHero",
								m(
									".app." + legacyCSS.centeredHeader,
									[
										"Found",
										store.prop("items").get().length ||
											R.sum(
												store.prop("itemCategories").get().map(R.prop("count"))
											),
										"Items",
									].join(" ")
								)
							),

							m(".app." + legacyCSS.spreadRowsBigGap, [
								searchValues.map((a) =>
									m(HarthInput, {
										query: store.prop(a.prop),
										type: "text",
										sl: {
											clearable: true,
											size: "medium",
											// [a.label ? 'label' : '']: a.label,
											helpText: a.help,
										},
									})
								),
							]),

							m(HarthSelect, {
								query: store.prop("supplier_items_industry"),
								options: industryCategories,
								sl: {
									multiple: true,
									placeholder: "Used in industries",
									size: "medium",
									class: "label-on-left",
									label: "Industry",
									helpText: "Filter specific to an Industry",
									clearable: true,
								},
							}),

							m(
								".app." + legacyCSS.spreadRows,
								numberValues.map((a) => {
									const dateype = a.type == "date"
									return [
										m(
											".app." + legacyCSS.leftTitles,
											pretty(a.min, {
												_min: "",
												_max: "",
												locations_: "",
												supplier_: "",
											})
										),

										m(".app." + legacyCSS.spreadColumnsSpaceBetween, [
											dateype
												? m(HarthInput, {
														query: store.prop(a.min),
														type: "date",
														sl: {
															helpText: "Min. " + store.prop(a.min).get(),
															size: "small",
														},
												  })
												: m(HarthRange, {
														query: store.prop(a.min),
														sl: {
															helpText: "Min. " + store.prop(a.min).get(),
															max: store.prop(a.minbound).get(),
														},
												  }),

											dateype
												? m(HarthInput, {
														query: store.prop(a.max),
														type: "date",
														sl: {
															helpText: "Max. " + store.prop(a.max).get(),
															size: "small",
														},
												  })
												: m(HarthRange, {
														query: store.prop(a.max),
														sl: {
															helpText: "Max. " + store.prop(a.max).get(),
															max: store.prop(a.maxbound).get(),
														},
												  }),
										]),
									]
								})
							),
						]),
					],
				}),

				// m(".app." + legacyCSS.centeredHeader, "DropOff"),
				m(HarthAvatar, {
					size: "2.6em",
					query: store.prop("state"),
					// slButton: {},
					sl: {
						image: store.prop("user").get().picture,
						// shape: "rounded",
						style: {
							"padding-inline": "var(--sl-spacing-x-small)",
						},
					},
				}),
			])
		),
	]
}

function SettingsView() {
	return {
		view: () => "SETTINGS",
	}
}

function blinkingDots(prefix: string, check: boolean) {
	return check
		? [
				prefix,
				m(".app." + legacyCSS.loader__dot, " ."),
				m(".app." + legacyCSS.loader__dot, "."),
				m(".app." + legacyCSS.loader__dot, "."),
		  ]
		: [prefix]
}

function HomeView() {
	const { store } = useStore()
	const state = store.prop("state")
	const orders = store.prop("orders")
	const map = store.prop("map")

	return {
		view: () =>
			m(".app." + legacyCSS.spreadRowsBigGap, [
				orders.get().length
					? // ? addressMap(v)
					  m(HarthMap, {
							id: "orders-map",
							map,
					  })
					: null,

				orders.get().length
					? m(".app." + legacyCSS.centeredHeader, "ORDERS")
					: m(
							".app." + legacyCSS.centeredRows,
							m(
								HarthButton,
								{
									query: state,
									sl: {
										size: "large",
										variant: "default",
										outline: true,
										value: "Search",
										class: "whitebackground",
									},
								},
								"Search our Catalogue"
							)
					  ),

				orders.get().map((o) =>
					m(
						HarthCard,
						{ sl: { class: "" } },

						o.status == "Picking up Order" ||
							o.status == "Order on its way" ||
							o.status == "Finding a Driver"
							? m(HarthProgressBar, {
									sl: {
										slot: "header",
										value:
											o.status == "Finding a Driver"
												? 10
												: o.status == "Order on its way"
												? 75
												: o.status == "Picking up Order"
												? 40
												: o.status == "Delivered"
												? 100
												: 0,
									},
							  })
							: null,
						m(".app." + legacyCSS.spreadRows, [
							m(
								".app." + legacyCSS.rightTitles,
								m(
									".app." + legacyCSS.spreadColumnsSpaceBetweenEnd,
									blinkingDots(
										o.status,
										o.status == "Finding a Driver" ||
											o.status == "Order on its way" ||
											o.status == "Picking up Order"
									)
								)
							),

							m(".app." + legacyCSS.centeredTitles, o.address),

							m(".app." + legacyCSS.spreadColumnsSpaceBetween, [
								m(".app." + legacyCSS.lefTtext, "Due By"),
								m(
									".app." + legacyCSS.lefTtext,
									moment(o.deliverytime).format("ddd Do, HH:mm:ss")
								),
							]),

							m(".app." + legacyCSS.spreadColumnsSpaceBetween, [
								m(".app." + legacyCSS.lefTtext, "Estimated Arrival"),
								m(
									".app." +
										(o.status != "Delivered"
											? legacyCSS.goldtext
											: legacyCSS.lefTtext),
									moment(o.eta).format("ddd Do, HH:mm:ss")
								),
							]),

							m(".app." + legacyCSS.spreadColumnsSpaceBetween, [
								m(".app." + legacyCSS.centeredTitles, o.description),
							]),

							m(
								".app." + legacyCSS.lefTtext,
								(o.items || []).map((f: string) =>
									m(".app." + legacyCSS.dotpoint, f)
								)
							),
						])
					)
				),
			]),
	}
}

function CheckoutView(v: any) {
	const { store, l, b, addressError } = useStore()

	const info = store.prop("info")
	const order = store.prop("order")
	const checkoutindex = store.prop("checkoutindex")
	const timeframe = store.prop("timeframe")
	const vehicle = store.prop("vehicle").get()

	const contact_name = store.prop("order_contact_name").get()
	const phone = store.prop("order_phone").get()
	const email = store.prop("order_email").get()
	const amount = store
		.prop("checkoutindex")
		.get.map((c) => R.sum(c.map((i) => i.total)) ?? 0)

	const address = store.prop("FormalAddress").get()

	const card = (i: deliveryType) =>
		m(
			HarthCard,
			{ sl: { class: "boxShadows" } },
			m(
				".app.",
				{ slot: "header" },
				m(".app." + legacyCSS.leftTitles, i.supplier),
				m(".app." + legacyCSS.helper, i.location)
			),
			m(
				".app." + legacyCSS.spreadRows,
				i.items.map((d) => {
					const item = store.prop("basket").unnest().where`
						(i) =>
							i.supplier_items_id == ${d.supplier_items_id} &&
							i.supplier_locations_id == ${d.supplier_locations_id}
					`

					return m(
						".app." + legacyCSS.spreadColumnsSpaceBetween,

						m(
							HarthButton,
							{
								query: item.prop("quantity"),
								postop: updateCheckout,
								sl: {
									size: "small",
									variant: "default",
									outline: false,
									value: 0,
									class: "whitebackground",
									disabled: order.get() != "",
								},
							},
							[
								m(HarthIcon, {
									sl: {
										name: "x",
										slot: "prefix",
										style: { "font-size": "12px" },
									},
								}),
							]
						),

						// , image
						m("img", {
							src: item.prop("supplier_items_image").get(),
							style: {
								"object-fit": "scale-down",
								width: "15%",
								height: "15%",
							},
						}),

						// , quantity
						m(HarthInput, {
							query: item.prop("quantity"),
							postop: updateCheckout,
							type: "number",
							sl: {
								size: "small",
								placeholder: "Qty",
								class: "tightwhite",
								disabled: order.get() != "",
							},
						}),

						// , = total
						m(
							".app." + legacyCSS.helper,
							" = " +
								money(
									d.total
										? d.total
										: item.prop("supplier_locations_items_cost").get() *
												item.prop("quantity").get(),
									0
								)
						),

						// , info
						m(
							HarthButton,
							{
								query: info,
								sl: {
									size: "small",
									variant: "default",
									outline: false,
									value: item.get(),
									class: "whitebackground",
								},
							},
							[
								m(HarthIcon, {
									sl: {
										name: "info-circle",
										slot: "prefix",
										style: { "font-size": "18px" },
									},
								}),
							]
						)
					)
				})
			)
		)

	store.prop("order").get()

	v.useEffect({ name: "checkout-redraw" }, function* () {
		const { o } = useStore()

		yield [o]
		m.redraw()
	})

	return {
		view: () =>
			m(
				".app." + legacyCSS.spreadRows,

				m(".app.", deliveryInputs(v)),

				m(".app." + legacyCSS.leftHeader, "Items"),

				centeredSpinner(
					l.get(),
					"centeredHero",
					m(
						".app." + legacyCSS.spreadRowsSmallGap,
						checkoutindex.get().map((i) => card(i))
					)
				),

				m(".app." + legacyCSS.leftHeader, "Checkout"),

				// special descriptions
				autocomplete(
					v,
					[],
					store.prop("order_description"),
					store.prop("newDescription"),
					"Description",
					"input",
					"100%",
					false,
					null
				),

				m(HarthTextArea, {
					query: store.prop("order_instructions"),
					type: "text",
					sl: {
						placeholder: "",
						size: "small",
						helpText: "Delivery Instructions",
					},
				}),

				m(
					HarthCard,
					m(
						".app." + legacyCSS.spreadRowsSmallGap,
						// !store.prop("user").get().cards
						// || !store.prop("user").get().cards.length
						// ? m(
						// 	HarthButton,
						// 	{
						// 		query: info,
						// 		sl: {
						// 			size: "large",
						// 			variant: "default",
						// 			outline: false,
						// 			value: {},
						// 			href: ''
						// 		},
						// 	},
						// 	"Add Credit/Debit Card"
						// )
						// : m(HarthSelect, {
						// 	query: store.prop("charge"),
						// 	options:
						// 		(store.prop("user").get().cards || []).map((c: any) => c.no),
						// 		// prop("cards")..get(),
						// 	sl: {
						// 		multiple: false,
						// 		placement: "bottom",
						// 		size: "medium",
						// 		hoist: true,
						// 		helpText: "Credit Card",
						// 		clearable: false,
						// 		class: "borderBottom",
						// 	},
						// }),

						m(".app." + legacyCSS.spreadColumnsSpaceBetween, [
							m(".app." + legacyCSS.helper, "Item Fees"),
							m(
								".app." + legacyCSS.helper,
								" = " +
									money(R.sum(checkoutindex.get().map(R.prop("total"))), 0)
							),
						]),

						m(".app." + legacyCSS.spreadColumnsSpaceBetween, [
							m(".app." + legacyCSS.helper, "Delivery Fees"),
							m(
								".app." + legacyCSS.helper,
								" = " +
									money(
										R.sum(checkoutindex.get().map(R.prop(timeframe.get()))),
										0
									)
							),
						]),

						m(".app." + legacyCSS.spreadColumnsSpaceBetween, [
							m(".app." + legacyCSS.helper, "Service Fees"),
							m(
								".app." + legacyCSS.helper,
								" = " +
									money(R.sum(checkoutindex.get().map(R.prop("servicefee"))), 0)
							),
						]),

						m(".app." + legacyCSS.spreadColumnsSpaceBetween, [
							m(".app.", "Total"),
							m(
								".app.",
								" = " +
									money(
										R.sum(checkoutindex.get().map(R.prop("total"))) +
											R.sum(checkoutindex.get().map(R.prop(timeframe.get()))) +
											R.sum(checkoutindex.get().map(R.prop("servicefee"))),
										0
									)
							),
						]),

						() =>
							bacta.Stream.merge([
								store.prop("checkoutindex").get as any,
								store.prop("order").get as any,
							]).map(([,]) =>
								store.prop("order").get() == "processing" &&
								amount.get() > 0 &&
								store.prop("ValidatedAddress").get() != "" &&
								addressError.get() == ""
									? m(HarthStripe, {
											group_payment_id: (
												store.prop("checkoutindex").get()[0] || {}
											).group_payment_id,
											error: store.prop("PaymentError").get,
											currency: "aud",
											address: store.prop("FormalAddress").get,
											amount:
												(Number(amount.get()).toFixed(2) as unknown as number) *
												10 *
												10,
											writeToLoad,
											makeOrder,
											successfulOrder,
											state: store.prop("state"),
											options: {
												defaultValues: {
													billingDetails: {
														name: contact_name,
														email: email,
														phone: phone,
														address: {
															line2: address?.address?.district,
															line1: [
																address?.address?.houseNumber,
																address?.address?.street,
															].join(" "),
															city: address?.address?.city,
															state: address?.address?.state,
															country: address?.address?.countryName,
															postal_code: address?.address?.postalCode,
														},
													},
												},
											},
											appearance: {
												theme: "stripe",
											},
									  })
									: null
							),

						() =>
							bacta.Stream.merge([
								store.prop("checkoutindex").get as any,
								store.prop("order").get as any,
							]).map(([,]) =>
								amount.get() > 0 &&
								store.prop("ValidatedAddress").get() != "" &&
								addressError.get() == ""
									? m(
											HarthButton,
											{
												id: "submit-pay",
												query:
													store.prop("order").get() != "processing"
														? store.prop("order")
														: store.prop("paySubmit"),
												sl: {
													size: "large",
													variant: "default",
													outline: false,
													value: !store.prop("order").get()
														? "processing"
														: "Payment Attempted",
													class: "yellowbackground",
												},
												disabled:
													store.prop("ValidatedAddress").get() == "" ||
													store.prop("order").get() != "processing" ||
													b.get().every((i) => i.quantity == 0) ||
													addressError.get() != "",
											},
											css`
												& {
													display: grid;
												}
											`,
											(store.prop("order").get() != "processing"
												? "Checkout "
												: "Pay ") + money(amount.get())
									  )
									: null
							),

						() =>
							store
								.prop("PaymentError")
								.get.map((o) =>
									o
										? m(
												"." + legacyCSS.centeredTitles,
												{ style: { color: "red" } },
												o
										  )
										: null
								)
					)
				),

				m(
					HarthDialog,
					{ open: info.get().supplier_items_id, sl: { noHeader: true } },
					m(".app." + legacyCSS.spreadRowsSmallGap, [
						m(
							HarthButton,
							{
								query: info as bacta.Store<object>,
								sl: {
									size: "medium",
									variant: "primary",
									outline: true,
									value: {},
								},
							},
							"Close"
						),

						ItemView({ backbutton: false, i: info, orderBanner: false }),
					])
				)
			),
	}
}

function closeSockets() {
	const { store } = useStore()
	store
		.prop("orders")
		.get()
		.forEach((o) => {
			o.ws ? o.ws.close(1000, "Normal Closed") : null
		})
}

async function getAddresses() {
	const { d, store } = useStore()

	if (!d.get()) {
		store.prop("nearAddressOptions").update(
			() =>
				[...new Set(store.prop("addressOptions").get())].map((d) => ({
					title: d,
				})) as object[]
		)
	}

	if (
		d.get() != store.prop("ValidatedAddress").get() ||
		(!d.get() &&
			store.prop("ValidatedWebAddress").get().lat &&
			store.prop("ValidatedWebAddress").get().lng)
	) {
		if (!d.get() && store.prop("FormalAddress").get().title) {
			store.prop("ValidatedAddress").update(() => "")
		} else {
			store.prop("AddressError").update(() => "")
			const addresses = await here(
				d.get(),
				store.prop("ValidatedWebAddress").get()
			)

			if (
				!d.get() &&
				store.prop("ValidatedWebAddress").get().lat &&
				store.prop("ValidatedWebAddress").get().lng
			) {
				store
					.prop("addressOptions")
					.update(() =>
						store.prop("addressOptions").get().concat(addresses[0].title)
					)
			}

			!d.get() && addresses.length >= 1
				? store.prop("FormalAddress").update(() => addresses[0] as any)
				: null

			d.get()
				? store.prop("nearAddressOptions").update(() => addresses as object[])
				: null
		}
	}
}

async function getPrices() {
	const { store, addressError } = useStore()

	addressError.update(() => "")

	const checkout = (await sherpa({
		endpoint: "quote",
		// NOTE WHEN YOU SEND SHERPA "AUSTRALIA" IN THE ADDRESS, THE ADDRESS VALIDATION IS AMBIGIOUS
		delivery_address: store
			.prop("ValidatedAddress")
			.get()
			.replace(" Australia", ""),
		items: store.prop("basket").get(),
		paymentid: null,
		group_payment_id: "",
		user_id: store.prop("user").get().user_id,
		deliveryDetails: {
			vehicle: store.prop("vehicle").get(),
		},
	})) as Array<deliveryType>

	checkout.forEach((check) => {
		const timeindex = R.indexBy(
			R.prop("delivery_option"),
			check.delivery_options
		)

		check["Now"] = timeindex["5"] ? timeindex["5"].price : 0
		check["Within2hours"] = timeindex["0"] ? timeindex["0"].price : 0
		check["Within4hours"] = timeindex["1"] ? timeindex["1"].price : 0
		check["SameDay"] = timeindex["2"] ? timeindex["2"].price : 0

		store.prop("vehicle").update(() => String(check.deliveryoption))
		store.prop("vehilceOptions").update(() => check.vehicleoptions)
	})

	const autodescription = checkout.map(R.prop("description"))

	const defaultime = store
		.prop("availableTimeFrames")
		.get()
		.map((af) => af.replaceAll(" ", ""))
		.filter((af) => checkout.every((co: any) => co[af] > 0))[0] as
		| "Now"
		| "Within2hours"
		| "Within4hours"
		| "SameDay"

	const description = store.prop("order_description").get()

	store
		.prop("order_description")
		.update(() => (description ? description : autodescription.join(", ")))

	if (!store.prop("timeframe").get()) {
		store.prop("timeframe").update(() => defaultime)
	}

	store
		.prop("order_contact_name")
		.update(() => store.prop("user").get().name || "")
	store.prop("order_phone").update(() => store.prop("user").get().phone || "")
	store.prop("order_email").update(() => store.prop("user").get().email || "")
	store.prop("checkoutindex").update(() => checkout)

	if (checkout.some((e) => e.error)) {
		const error = R.uniq(checkout.map((e) => e.error))
			.filter(R.identity)
			.join(",")

		addressError.update(() => error)
	}

	writeToLoad(false)
}

async function successfulOrder() {
	const { store, b } = useStore()
	// clear basket to stop repeat roders
	b.update(() => [])

	// reset items to wipe stored quantities and prevent automatic re-adds to the basket
	const ic = store
		.prop("itemCategories")
		.get()
		.find((ic) => ic.supplier_items_category == store.prop("category").get())

	await getItems(ic)

	await setMetaData(store, store.prop("user").get())

	// reset the order state for new orders to be able to take place
	store.prop("order").update(() => "")

	return true
}

async function makeOrder() {
	const { store } = useStore()
	const group_payment_id = store.prop("checkoutindex").get()[0].group_payment_id
	const order = {
		endpoint: "order",
		// NOTE WHEN YOU SEND SHERPA "AUSTRALIA" IN THE ADDRESS, THE ADDRESS VALIDATION IS AMBIGIOUS
		delivery_address: store.prop("deliveryTo").get().replace(" Australia", ""),
		full_delivery_address: store.prop("deliveryTo").get(),
		items: store.prop("basket").get(),
		user_id: store.prop("user").get().user_id,
		group_payment_id: group_payment_id,
		paymentid: null,
		deliveryDetails: {
			item_description: store.prop("order_description").get(),
			instructions: store.prop("order_instructions").get(),
			contact_name: store.prop("order_contact_name").get(),
			phone_number: store.prop("order_phone").get(),
			email_address: store.prop("order_email").get(),
			vehicle_id: store.prop("vehicle").get(),
			delivery_option: {
				Now: 5,
				Within2hours: 0,
				Within4hours: 1,
				SameDay: 2,
			}[store.prop("timeframe").get()],
		},
	}

	return order
}

async function getOrders() {
	closeSockets()

	const { store } = useStore()

	const dborders = (await sql`
		With uiorders as (
			select 
				*
				,CAST("sherpa_order" -> 'pickup_address' ->> 'longitude' AS decimal) as pickuplng
				,CAST("sherpa_order" -> 'pickup_address' ->> 'latitude' AS decimal) as pickuplat
				,(supplier_name || ' - ' || ("sherpa_order" -> 'pickup_address' ->> 'validated_address')) as pickupaddress
				,CAST("sherpa_order" -> 'delivery_address' ->> 'longitude' AS decimal) as deliverylng
				,CAST("sherpa_order" -> 'delivery_address' ->> 'latitude' AS decimal) as deliverylat	
				,("sherpa_order" -> 'delivery_address' ->> 'validated_address') as deliveryaddress
				,("sherpa_order" ->> 'deliver_for') as deliverytime
			from app.orders
		)
		select 
			delivery_id
			,pickuplng
			,pickuplat
			,deliverylng
			,pickupaddress
			,deliverylat
			,deliveryaddress
			,deliverytime

			,order_status as "status" 
			,order_address as "address"
			,order_eta as "eta"

			,sum( order_cost * order_amount ) as "itemcost"
			,sum( order_cost * order_amount * 0.2 ) as "servicefee"
			,array_agg( order_amount || ' x ' || order_name ) as "items"	
			
			,array_agg( "sherpa_order" ) as "sherpa"
			,string_agg( distinct  "sherpa_order" -> 'delivery_tracking' ->> 'token', ', ' ) as "token"
			
			,sum( distinct cast( "sherpa_order" ->> 'amount' as decimal ) ) as "deliveryfee"
			,string_agg( distinct "sherpa_order" ->> 'item_description', ', ' ) as "description"
		from uiorders 
		group by 
			delivery_id
			, order_status
			, order_address
			, order_eta
			, pickuplng
			, pickuplat
			, deliverylng
			, pickupaddress
			, deliverylat
			, deliveryaddress
			, deliverytime
		order by order_eta DESC
	`) as Array<orderType>

	dborders.filter(
		(o) =>
			o.status == "Picking up Order" ||
			o.status == "Order on its way" ||
			o.status == "Finding a Driver"
	).length && store.prop("state").get() != "Home"
		? store.prop("state").update(() => "Home")
		: null

	store.prop("orders").update(() => dborders)

	dborders.forEach((uio) => {
		if (
			uio.status == "Picking up Order" ||
			uio.status == "Order on its way" ||
			uio.status == "Finding a Driver"
		) {
			const order = store.prop("orders").unnest()
				.where`torder => torder.delivery_id == ${uio.delivery_id}`

			const ns = new WebSocket(
				"wss://rtm.sherpa.net.au/cable?token=" + uio.token
			)
			order.prop("ws").update(() => ns)

			// o.ws
			order
				.prop("ws")
				.get()
				.addEventListener("open", () => {
					if (order.prop("ws").get().readyState == 1) {
						order
							.prop("ws")
							.get()
							.send(
								JSON.stringify({
									command: "subscribe",
									identifier: '{"channel":"LocationsChannel"}',
								})
							)
					}
				})

			// o.ws
			order
				.prop("ws")
				.get()
				.addEventListener("message", async (event: any) => {
					const data = JSON.parse(event.data) as { message: gpsType }

					if (data.message) {
						if (data.message.lat && data.message.lng && data.message.eta) {
							const gps = {
								lat: Number(data.message.lat),
								lng: Number(data.message.lng),
								eta: data.message.eta,
								position: data.message.position,
								description: order.prop("description").get(),
								delivery_id: order.prop("delivery_id").get(),
							}

							const [status] = await sql`
								select 
									order_status
								from app.orders
								where delivery_id = ${order.prop("delivery_id").get()}
								limit 1
							`

							order.prop("currentgps").update(() => gps)
							order.prop("status").update(() => status.order_status)
							order.prop("eta").update(() => data.message.eta)

							const newarray = store.prop("trackers").get().concat(gps)

							store.prop("trackers").update(() => newarray)

							const rorders = store.prop("orders").get()

							store.prop("orders").update(() => rorders)

							if (status.order_status == "Delivered") {
								order.prop("ws").get().close(1000, "Normal Closed")
								order.prop("marker").get().remove()
								order.prop("pickupmarker").get().remove()
								order.prop("deliverymarker").get().remove()
							}
						}
					}
				})
		}
	})

	writeToLoad(false)
}

const typingInterval = 800
const machineInterval = 200
const debounceAddress = debounce(getAddresses, typingInterval)
const debouncePrices = debounce(getPrices, typingInterval)
const debounceSearchCategories = debounce(searchCategories, typingInterval)
const debounceSearchItems = debounce(searchItems, typingInterval)
const debounceOrders = debounce(getOrders, machineInterval)

function writeToLoad(x: boolean) {
	const { l } = useStore()
	l.get() != x ? l.update(() => x) : null
}

function useAddress(v: bacta.Vnode) {
	v.useEffect({ name: "useAddress" }, function* () {
		const { d, ValidatedWebAddress } = useStore()
		yield [d, ValidatedWebAddress]

		d.get() || (ValidatedWebAddress.get().lat && ValidatedWebAddress.get().lng)
			? debounceAddress()
			: []
	})
}

function setAddress(v: bacta.Vnode) {
	v.useEffect({ name: "setaddress" }, function* () {
		const { ValidatedAddress, d, FormalAddress, store } = useStore()
		yield FormalAddress

		if (FormalAddress.get().title) {
			d.update(() => FormalAddress.get().title)
			if (
				FormalAddress.get().resultType == "houseNumber" ||
				FormalAddress.get().resultType == "place"
			) {
				ValidatedAddress.update(() => FormalAddress.get().title)
				store.prop("nearAddressOptions").update(() => [])
				store
					.prop("addressOptions")
					.update(() =>
						[FormalAddress.get().title].concat(
							store.prop("addressOptions").get()
						)
					)
			}
		}
	})
}

function useSearchItems(v: bacta.Vnode) {
	v.useEffect({ name: "search-items" }, function* () {
		const {
			store,
			name,
			itemSearch,
			items_name,
			items_description,
			items_weight_min,
			items_height_min,
			items_wdith_min,
			items_length_min,
			items_weight_max,
			items_height_max,
			items_wdith_max,
			items_length_max,
			items_category,
			items_industry,
			items_specifications,
			items_features,
			locations_items_cost_min,
			locations_items_cost_max,
			locations_items_available,
			locations_address,
		} = useStore()

		const s = (yield itemSearch) as State["searchItems"]

		yield [
			name,
			items_name,
			items_description,
			items_weight_min,
			items_height_min,
			items_wdith_min,
			items_length_min,
			items_weight_max,
			items_height_max,
			items_wdith_max,
			items_length_max,
			items_category,
			items_industry,
			items_specifications,
			items_features,
			locations_items_cost_min,
			locations_items_cost_max,
			locations_items_available,
			locations_address,
		]

		writeToLoad(true)
		// todo-james we don't really need the debounce
		// could do the debounce in useEffect instead
		debounceSearchItems(store, s)
	})
}

function useSearchCategories(v: bacta.Vnode) {
	v.useEffect({ name: useSearchCategories.name }, function* () {
		const {
			store,
			categorySearch: s,
			name: n,
			items_name: iname,
			items_description: description,
			items_weight_min: weight_min,
			items_height_min: height_min,
			items_wdith_min: wdith_min,
			items_length_min: length_min,
			items_weight_max: weight_max,
			items_height_max: height_max,
			items_wdith_max: wdith_max,
			items_length_max: length_max,
			items_category: category,
			items_industry: industry,
			items_specifications: specifications,
			items_features: features,
			locations_items_cost_min: items_cost_min,
			locations_items_cost_max: items_cost_max,
			locations_items_available: items_available,
			locations_address: address,
			ValidatedWebAddress: vadd,
		} = useStore()

		yield [
			n,
			iname,
			description,
			weight_min,
			height_min,
			wdith_min,
			length_min,
			weight_max,
			height_max,
			wdith_max,
			length_max,
			category,
			industry,
			specifications,
			features,
			items_cost_min,
			items_cost_max,
			items_available,
			address,
			vadd,
		]

		writeToLoad(true)
		debounceSearchCategories(store, yield s)
	})
}

function useMap(v: bacta.Vnode) {
	v.useEffect({ name: useMap.name }, function* () {
		const { map, store } = useStore()

		const mapp = yield map
		const intital = (store.prop("orders").get() || []).filter(
			(uio) =>
				uio.status == "Picking up Order" ||
				uio.status == "Order on its way" ||
				uio.status == "Finding a Driver"
		)

		if (!intital.length && mapp.fitBounds) {
			mapp.locate({
				setView: true,
				maxZoom: 18,
			})
		} else {
			const items = intital
				.map((uio) => {
					const deliverygps = [Number(uio.deliverylat), Number(uio.deliverylng)]
					const pickupgps = [Number(uio.pickuplat), Number(uio.pickuplng)]
					if (mapp.fitBounds) {
						const deliverymarker = L.marker(
							[Number(uio.deliverylat), Number(uio.deliverylng)],
							{
								icon: craneMarker,
							}
						)
							.addTo(mapp)
							.bindPopup(uio.deliveryaddress)

						const pickupmarker = L.marker(
							[Number(uio.pickuplat), Number(uio.pickuplng)],
							{
								icon: toolsMarker,
							}
						)
							.addTo(mapp)
							.bindPopup(uio.pickupaddress)

						const order = store.prop("orders").unnest()
							.where`torder => torder.delivery_id == ${uio.delivery_id}`

						order.prop("pickupmarker").update(() => pickupmarker)
						order.prop("deliverymarker").update(() => deliverymarker)

						mapp.setView(pickupgps, 17, {
							animate: true,
							maxZoom: 17,
						})

						// const samepoint =
						// 	uio.deliverylat == uio.pickuplat &&
						// 	uio.deliverylng == uio.pickuplng
						// if (!samepoint) {
						// 	mapp.fitBounds([deliverygps, pickupgps], {
						// 		animate: true,
						// 		padding: [25, 25],
						// 		maxZoom: 17,
						// 	})
						// }
					}
					return [deliverygps, pickupgps]
				})
				.flat(1)

			if (items.length >= 2 && items.map((a) => a.length >= 2)) {
				mapp.flyToBounds(items, {
					animate: true,
					padding: [25, 25],
					maxZoom: 17,
					duration: 2,
				})
			}
		}
	})
}

function useMapMarkers(v: bacta.Vnode) {
	v.useEffect({ name: useMapMarkers.name }, function* () {
		const { store, t } = useStore()
		const trackers = (yield t) as State["trackers"]
		const map = store.prop("map").get()

		;(store.prop("orders").get() || []).forEach((uio) => {
			const order = store.prop("orders").unnest()
				.where`torder => torder.delivery_id == ${uio.delivery_id}`

			if (uio.prevgps) {
				uio.marker.remove()

				L.marker([uio.prevgps.lat, uio.prevgps.lng], { icon: dotMarker })
					.addTo(map)
					.bindPopup(
						uio.status == "Order on its way"
							? uio.description + " on the way"
							: "Driver picking up " + uio.description
					)
			}

			if (uio.currentgps) {
				const marker = L.marker([uio.currentgps.lat, uio.currentgps.lng], {
					icon: outlineddotMarker,
				})
					.addTo(map)
					.bindPopup("Driver picking up " + uio.description)

				order.prop("marker").update(() => marker)

				order.prop("prevgps").update(() => uio.currentgps)

				map.fitBounds(
					[
						[Number(uio.deliverylat), Number(uio.deliverylng)],
						[Number(uio.currentgps.lat), Number(uio.currentgps.lng)],
					],
					{ padding: [25, 25] }
				)
			}
		})
	})
}

function usePricingAutoTab(v: bacta.Vnode) {
	v.useEffect({ name: usePricingAutoTab.name }, function* () {
		const { s, b, d, ValidatedAddress, store } = useStore()

		const [state, basket, deliveryto, validatedto, timeframe, vehicle] =
			(yield [
				s,
				b,
				d,
				ValidatedAddress,
				store.prop("timeframe"),
				store.prop("vehicle"),
			]) as [
				State["state"],
				State["basket"],
				State["deliveryTo"],
				State["ValidatedAddress"],
				State["timeframe"],
				State["vehicle"]
			]

		const tab = document.querySelector("sl-tab-group")
		if (
			tab &&
			state == "Checkout" &&
			d.get() &&
			d.get() == ValidatedAddress.get()
		) {
			tab.show("Checkout")
			writeToLoad(true)
			debouncePrices()
		} else if (tab && state == "Search") {
			tab.show("Search")
		}
	})
}

function useOrdersAutoTab(v: bacta.Vnode) {
	v.useEffect({ name: useOrdersAutoTab.name }, function* () {
		const { s, o } = useStore()
		const state = (yield s) as ReturnType<typeof s.get>
		const order = (yield o) as ReturnType<typeof o.get>
		const tab = document.querySelector("sl-tab-group")
		if (tab && state == "Home" && order == "") {
			tab.show("Home")
			writeToLoad(true)
			debounceOrders()
		}
	})
}

function showloading(v: bacta.Vnode) {
	v.useEffect({ name: "loading" }, function* () {
		const { l } = useStore()

		yield l
		m.redraw()
	})
}

function OrderView(i: bacta.Store<itemType>) {
	const { store } = useStore()
	return m(".app." + legacyCSS.spreadColumnsSpaceAround, [
		m(
			".app.",
			{ style: { "align-items": "flex-end", "font-size": "22px" } },
			money(i.get().supplier_locations_items_cost, 0)
		),
		m(
			".app.",
			{ style: { "max-width": "35%" } },
			m(".app." + legacyCSS.centeredColumnsNoGap, [
				m(
					HarthButton,
					{
						query: i.prop("quantity"),
						postop: updateCheckout,
						sl: {
							size: "small",
							variant: "default",
							outline: true,
							value: i.prop("quantity").get() - 1,
							class: "yellowbackground",
							disabled: i.prop("quantity").get() == 0,
						},
					},
					[
						m(HarthIcon, {
							sl: {
								name: "dash-lg",
								slot: "prefix",
								style: { "font-size": "18px" },
							},
						}),
					]
				),
				m(HarthInput, {
					query: i.prop("quantity"),
					type: "number",
					postop: updateCheckout,
					sl: {
						type: "number",
						inputmode: "numeric",
						noSpinButtons: false,
						min: 0,
						step: 1,
						clearable: false,
						size: "large",
						placeholder: "",
						class: "tight",
					},
				}),
				m(
					HarthButton,
					{
						query: i.prop("quantity"),
						postop: updateCheckout,
						sl: {
							size: "small",
							variant: "default",
							outline: false,
							value: i.prop("quantity").get() + 1,
							class: "yellowbackground",
						},
					},
					[
						m(HarthIcon, {
							sl: {
								name: "plus-lg",
								slot: "suffix",
								style: { "font-size": "18px" },
							},
						}),
					]
				),
			])
		),

		m(
			HarthButton,
			{
				query: store.prop("state"),
				sl: {
					size: "medium",
					variant: "default",
					outline: true,
					value: "Checkout",
					class: "blackbackground",
					disabled: i.prop("quantity").get() <= 0,
				},
			},
			"ORDER NOW"
		),
	])
}

function filterButton({ notext = false }) {
	const { store } = useStore()
	return m(
		".app." + legacyCSS.centeredColumns,
		m(
			HarthButton,
			{
				query: store.prop("drawOpen"),
				sl: {
					size: "medium",
					variant: "default",
					outline: false,
					value: true,
					class: "whitebackground",
				},
			},
			[
				notext ? "" : "Filters",
				m(HarthIcon, {
					sl: {
						name: "sliders",
						slot: "prefix",
						style: { "font-size": "24px", color: "#ffc735" },
					},
				}),
			]
		)
	)
}

function BackButton() {
	const { store } = useStore()
	const item = store.prop("item")
	const category = store.prop("category")

	return m(
		HarthButton,
		{
			query: item.get().supplier_items_id
				? (item as bacta.Store<object>)
				: (category as bacta.Store<string>),
			sl: {
				size: "small",
				variant: "default",
				outline: false,
				value: item.get().supplier_items_id ? {} : "",
				class: "whitebackground",
			},
		},
		[
			m(HarthIcon, {
				sl: {
					name: "chevron-left",
					slot: "prefix",
					style: { "font-size": "18px" },
				},
			}),
		]
	)
}

async function getItems(
	ic:
		| {
				supplier_items_category: any
				supplier_items_industry?: string
				supplier_items_icon: any
		  }
		| undefined
) {
	const { store, itemSearch } = useStore()
	writeToLoad(true)

	const category = store.prop("category")
	const headericon = store.prop("headerIcon")

	if (ic) {
		category.update(() => ic.supplier_items_category)
		headericon.update(() => ic.supplier_items_icon)
	}

	searchItems(store, itemSearch.get())
}

function finditem() {
	const { store } = useStore()
	const b = store.prop("item").get()
	const a = store.prop("items").unnest().where`
		(i) =>
			i.supplier_items_id == ${b.supplier_items_id} &&
			i.supplier_locations_id == ${b.supplier_locations_id}
	`

	return a
}

function ItemView({
	backbutton = true,
	i = null as bacta.Store<itemType> | null,
	orderBanner = true,
}) {
	const { l } = useStore()
	const item = i || finditem()

	return centeredSpinner(
		l.get(),
		"centeredHero",
		m(".app." + legacyCSS.spreadRows, [
			m(
				".app." + legacyCSS.centeredColumns,
				backbutton ? BackButton() : null,
				m(".app." + legacyCSS.centeredHeader, item.get().supplier_items_name)
			),
			m(
				".app." + legacyCSS.centeredColumns,
				{
					style: {
						"padding-bottom": "1em",
						"padding-top": "1em",
					},
				},
				m("img", {
					src: item.get().supplier_items_image,
					style: {
						"box-shadow": "0 0 14px lightgrey",
						"object-fit": "scale-down",
						width: "100%",
						height: "100%",
					},
				})
			),

			m(".app." + legacyCSS.spreadColumnsSpaceBetween, [
				m(".app." + legacyCSS.leftHeader, {}, "S/N"),
				m(".app.", {}, item.get().supplier_items_serial),
			]),

			m(".app." + legacyCSS.centeredtextsmall, item.get().supplier_name),
			m(
				".app." + legacyCSS.centeredtextsmall,
				item.get().supplier_locations_address
			),

			m(".app." + legacyCSS.centeredHeader, {}, "Features"),
			m(
				".app." + legacyCSS.lefTtext,
				item.get().supplier_items_description
				// m(HarthTree, {
				// 	items: item.get().supplier_items_features,
				// 	sl: { selection: "leaf" },
				// })
			),

			m(
				".app.",
				(item.get().supplier_items_features || []).map((f: string) =>
					m(".app." + legacyCSS.dotpoint, f)
				)
			),

			m(".app." + legacyCSS.centeredHeader, {}, "Dimensions"),
			(
				[
					"supplier_items_weight",
					"supplier_items_height",
					"supplier_items_wdith",
					"supplier_items_length",
				] as (keyof itemType)[]
			).map((d) =>
				m(
					".app." + legacyCSS.spreadColumnsSpaceBetweenNoGap,
					m(
						".app." + legacyCSS.lefTtext,
						pretty(d.replace("supplier_items_", ""))
					),
					item.get()[d] + (d == "supplier_items_weight" ? " Kg" : " mm")
				)
			),
			m(".app." + legacyCSS.centeredHeader, {}, "Specifications"),
			m(".app." + legacyCSS.lefTtext, item.get().supplier_items_specifications),
			orderBanner ? m(".app." + legacyCSS.orderFixed, OrderView(item)) : null,
		])
	)
}

function ItemSearchView() {
	const { store, l } = useStore()
	const item = store.prop("item")
	const items = store.prop("items")
	const itemid = store.prop("itemid")
	const locationid = store.prop("locationid")
	const headericon = store.prop("headerIcon")
	const category = store.prop("category")
	return m(".app." + legacyCSS.spreadRows, [
		m(
			".app." + legacyCSS.centeredColumnsNoGap,
			BackButton(),
			m(".app." + legacyCSS.centeredHero, category.get()?.toUpperCase())
		),
		filterButton({ notext: false }),
		centeredSpinner(
			l.get(),
			"centeredHero",
			m(
				".app." + legacyCSS.flexBoxes,
				items
					.get()
					.filter((i) => i.supplier_items_category == category.get())
					.map((i) =>
						m(
							"app." + legacyCSS.maxContent,
							m(
								HarthCard,
								{
									sl: {
										class: "boxShadows",
									},
									onclick: async () => {
										writeToLoad(true)

										itemid.update(() => i.supplier_items_id)
										locationid.update(() => i.supplier_locations_id)
										headericon.update(() => i.supplier_items_image)
										const dbitem = await sql`
												select
													*
													,0 as quantity
												from app.supplier_locations_items
												inner join app.supplier_items using (supplier_items_id)
												inner join app.supplier_locations using(supplier_locations_id)
												inner join app.suppliers on 
														app.supplier_locations.supplier_id = app.suppliers.supplier_id
												where 
													supplier_items_id = ${itemid.get()}
													and supplier_locations_id = ${locationid.get()} 
											`

										item.update(() => dbitem[0])

										writeToLoad(false)
									},
								},

								m(".app." + legacyCSS.spreadRowsNoGap, [
									m("img", {
										src: i.supplier_items_image,
										style: {
											"object-fit": "scale-down",
											width: "100%",
											height: "100%",
										},
									}),
									m(
										".app." + legacyCSS.cutcenteredTitles,
										i.supplier_items_name
									),
									m(
										".app." + legacyCSS.cutcenteredTitles,
										m(
											".app." + legacyCSS.helper,
											[
												i.supplier_name,
												"(",
												Number(i.calculate_distance).toFixed(1),
												"Km",
												")",
											].join(" ")
										)
									),
								]),

								m(
									".app." + legacyCSS.yellowbackground,
									{ slot: "footer" },
									m(".app." + legacyCSS.spreadRowsNoGap, [
										m(
											".app." + legacyCSS.centeredHeader,
											money(i.supplier_locations_items_cost, 0)
										),
										// m(".app." + legacyCSS.centeredtextsmall, i.supplier_name),
										// m(
										// 	".app." + legacyCSS.centeredtextsmall,
										// 	i.supplier_locations_address
										// ),
									])
								)
							)
						)
					)
			)
		),
	])
}

function CategorySearchView() {
	const { store, l } = useStore()
	const itemCategories = store.prop("itemCategories")
	return m(
		".app." + legacyCSS.spreadRowsSpaceBetween,
		m(
			".app." + legacyCSS.insidemargin,
			m(".app." + legacyCSS.spreadRows, [
				m(".app." + legacyCSS.centeredColumns, [
					m(
						HarthInput,
						{
							query: store.prop("search"),
							type: "text",
							sl: {
								clearable: true,
								size: "medium",
								pill: true,
								placeholder: "Search for Items",
								class: "boxShadows",
							},
						},
						[m(HarthIcon, { sl: { name: "search", slot: "prefix" } })]
					),
					filterButton({ notext: true }),
				]),
			])
		),

		centeredSpinner(
			l.get(),
			"",
			m(".app." + legacyCSS.spreadRowsSmallGap, [
				m(".app." + legacyCSS.spreadRowsNoGap, [
					m(".app." + legacyCSS.leftHeader, "RECENTLY VISISTED"),
					m(
						".app." + legacyCSS.spreadColumns,
						itemCategories
							.get()
							.slice(0, 4)
							.map((ic) =>
								m(".app." + legacyCSS.spreadRowsNoGap, [
									m(HarthIconButton, {
										sl: {
											onclick: async () => await getItems(ic),
											src: ic.supplier_items_icon.replace(".png", ".svg"),
											slot: "prefix",
											style: {
												"font-size": "72px",
												filter: "brightness(0)",
											},
										},
									}),
								])
							)
					),
				]),

				m(".app." + legacyCSS.spreadRows, [
					m(".app." + legacyCSS.leftHeader, "ALL"),
					m(
						".app." + legacyCSS.flexBoxes,
						itemCategories.get().map((ic) =>
							m(
								"app." + legacyCSS.maxContent,
								m(
									"app." + legacyCSS.spreadRowsNoGap,
									m(
										HarthCard,
										{
											sl: {
												class: "boxShadows",
											},
											onclick: async () => await getItems(ic),
										},

										m(
											".app.",
											{
												style: {
													width: "80%",
													height: "80%",
													display: "block",
													"margin-left": "auto",
													"margin-right": "auto",
												},
											},
											m("img", {
												src: ic.supplier_items_icon,
												style: {
													"object-fit": "scale-down",
													width: "100%",
													height: "100%",
												},
											})
										)
									),
									m(
										".app." + legacyCSS.spreadRowsNoGap,
										{ style: { "font-size": "18px" } },
										[
											m(
												".app." + legacyCSS.centeredHeader,
												["(", ic.count, ")"].join(" ")
											),
											m(
												".app." + legacyCSS.cutcenteredTitles,
												ic.supplier_items_category
											),
										]
									)
								)
							)
						)
					),
				]),
			])
		)
	)
}

function SearchView() {
	const { store } = useStore()
	const category = store.prop("category")
	const item = store.prop("item")
	return {
		view: () =>
			!category.get()
				? CategorySearchView()
				: !item.get().supplier_items_id
				? ItemSearchView()
				: ItemView({ backbutton: true, i: null, orderBanner: true }),
	}
}

function centeredSpinner(check: any, spinnercss: string, div: any) {
	return check
		? m(".app." + legacyCSS[spinnercss], m(HarthSpinner, { size: "6rem" }))
		: div
}

function Landing(v: bacta.Vnode<{}, {}>) {
	const { store, u } = useStore()
	const user = store.prop("user").get()

	return window.location.href == "https://dropoff.au/privacy_policy"
		? pp
		: window.location.host == "dropoff.au"
		? m("img", {
				src: websiteHome,
				style: {
					"object-fit": "scale-down",
					width: "100%",
					height: "100%",
				},
		  })
		: [
				!u.get() && user && !user.address && user.user_id
					? m(
							".app." + legacyCSS.landingSpread,
							m(".app." + legacyCSS.spreadRows, { style: { padding: "2em" } }, [
								m("img", {
									src: dologopin,
									style: {
										"object-fit": "scale-down",
										width: "100%",
										height: "100%",
									},
								}),

								m(".app." + legacyCSS.spreadRowsFatGap, [
									m(
										".app." + legacyCSS.centeredSmallHero,
										m(
											".app." + legacyCSS.yellowbackgroundBlacktext,
											"FIND & ORDER"
										),
										m(".app." + legacyCSS.yellowbackground, "TOOLS & HARDWARE")
									),

									newNearAddress(v, false),

									m(
										HarthButton,
										{
											query: store.prop("state"),
											sl: {
												size: "large",
												variant: "default",
												outline: true,
												value: "Search",
												class: "yellowbackgroundWithBorder",
												pill: true,
											},
										},
										m(HarthIcon, {
											sl: {
												name: "search",
												slot: "prefix",
												style: { "font-size": "32px" },
											},
										}),
										"FIND ME TOOLS"
									),
								]),
							])
					  )
					: m(
							".app." + legacyCSS.landingCenter,
							m(".app." + legacyCSS.spreadRowsSpaceBetween, {}, [
								m("img", {
									src: dologo,
									style: {
										"object-fit": "scale-down",
										width: "100%",
										height: "100%",
									},
								}),

								m(".app." + legacyCSS.centeredHeader, "Welcome"),

								centeredSpinner(
									u.get(),
									"centeredHero",
									m(
										HarthButton,
										{
											sl: {
												size: "large",
												variant: "default",
												outline: true,
												value: "",
												href: "api/auth/authorize",
												class: "whitebackground",
											},
										},
										"LOGIN / SIGN UP"
									)
								),
							])
					  ),
		  ]
}

const App: bacta.BactaComponent = (v: bacta.Vnode) => {
	const { store, s } = useStore(v)

	useSearchItems(v)
	useSearchCategories(v)
	useMap(v)
	useMapMarkers(v)
	usePricingAutoTab(v)
	useOrdersAutoTab(v)
	useAddress(v)
	setAddress(v)
	showloading(v)

	v.useEffect({ name: "initialize" }, function* () {
		yield initialize(store)
	})

	// store
	return () =>
		!s.get()
			? Landing(v)
			: m(".app." + legacyCSS.app, { style: { position: "relative" } }, [
					AppHeader(),
					m(AppTray, { v }),
			  ])
}

m.mount(document.body, App)
