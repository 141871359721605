import m, * as bacta from "bacta"
import { Option, OptionAttrs } from "../sl/option.js"

type BaseAttrs = {
	sl?: Partial<OptionAttrs>
}

export type MainAttrs = BaseAttrs

export const HarthOption: bacta.ClosureComponent<MainAttrs> = () => ({
	view: ({ attrs: { sl = {} } }) => {
		return m(
			Option,
			{
				value: sl.value,
			},
			sl.value?.replaceAll("_", " ")
		)
	},
})
