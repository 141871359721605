import m, * as bacta from "bacta"
import { Range, RangeAttrs } from "../sl/range.js"

export type MainAttrs = {
	query: bacta.Store<number>
	sl: Partial<RangeAttrs>
}

export const HarthRange: bacta.ClosureComponent<MainAttrs> = () => ({
	view: ({ attrs: { sl = {}, ...attrs } }) => {
		return m(Range, {
			"onsl-change"(e: InputEvent) {
				attrs.query.update(() => (e.target as HTMLInputElement)?.valueAsNumber)
			},
			value: attrs.query.get(),
			...sl,
		})
	},
})
