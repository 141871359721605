import m, * as bacta from "bacta"
import { TextArea, TextAreaAttrs } from "../sl/textarea.js"

type NumberAttrs = {
	type: "number"
	query: bacta.Store<number>
}

type TextAttrs = {
	type: "text"
	query: bacta.Store<string>
}

type BaseAttrs = {
	sl?: Partial<TextAreaAttrs>
	label?: string
}

export type MainAttrs = BaseAttrs & (NumberAttrs | TextAttrs)

export const HarthTextArea: bacta.ClosureComponent<MainAttrs> = () => ({
	view: ({ attrs: { sl = {}, ...attrs } }) => {
		return m(TextArea, {
			type: attrs.type,
			["onsl-input"](e: any) {
				if (attrs.type === "number") {
					attrs.query.update(
						() => (e.target as HTMLInputElement)?.valueAsNumber
					)
				} else {
					attrs.query.update(() => (e.target as HTMLInputElement)?.value)
				}
			},
			[attrs.type === "text" ? "value" : "valueAsNumber"]: attrs.query.get(),
			...sl,
		})
	},
})
